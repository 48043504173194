<template>
<div :class="[className]">

    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <title>Icon/chevron-up</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M12,10.4142136 L17.2928932,15.7071068 C17.6834175,16.0976311 18.3165825,16.0976311 18.7071068,15.7071068 C19.0976311,15.3165825 19.0976311,14.6834175 18.7071068,14.2928932 L12.7071068,8.29289322 C12.3165825,7.90236893 11.6834175,7.90236893 11.2928932,8.29289322 L5.29289322,14.2928932 C4.90236893,14.6834175 4.90236893,15.3165825 5.29289322,15.7071068 C5.68341751,16.0976311 6.31658249,16.0976311 6.70710678,15.7071068 L12,10.4142136 Z" id="path-3"></path>
    </defs>
    <g id="Icon/chevron-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <mask id="mask-3" fill="white">
            <use xlink:href="#path-3"></use>
        </mask>
        <use id="Shape" fill="#7A7E81" fill-rule="nonzero" xlink:href="#path-3"></use>
        <g id="Color/Base/Black" mask="url(#mask-3)" fill="#7A7E81">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        </g>
    </g>
</svg>
  </div>
</template>

<script>
export default {
  props: ['className']
}
</script>

<style>

</style>
