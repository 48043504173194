<template>
  <div :class="['dropdown-wrapper', `${name}-wrapper`]">
    <div :class="['dropdown-field', name]" :id="name">
      <label :class="['label', isLableFloating && 'floating']">{{
        label
      }}</label>

      <div v-if="!disabled && isOpen" @click="() => toggleDropDown(false)">
        <ChevronUp className="dropdown-icon" :id="name" />
      </div>
      <div v-else @click="() => toggleDropDown(true)">
        <CevronDown className="dropdown-icon" :id="name" />
      </div>

      <input
        class="select"
        :name="name"
        :value="disabled ? '' : resetSearchText ? '' : searchInputText"
        @input="onChangeHandler"
        readonly
        @focus="() => toggleDropDown(true)"
        :placeholder="placeholder"
        autocomplete="off"
        :disabled="disabled"
      />
      <!-- <option value="0" key="0">Please select</option> -->
      <div
        class="dropdown-options"
        v-if="!disabled && isOpen && filteredOptions && filteredOptions.length"
      >
        <div
          v-for="option in filteredOptions"
          :key="option.title"
          @click="(e) => option.disabled ? ()=>{console.log('clicked on disabled option')}: selectHandler(e, option)"
          :class="option.disabled ? 'disabled': ''"

        >
        <!-- <p>{{option.disabled ? 'disabled': 'enabled'}}</p> -->
          <p class="display-text">{{ option.title }} </p>
          <p class="display-description">{{ option.subtitle }}</p>
        </div>
      </div>

      <p v-if="helperText" class="helper-text">{{ helperText }}</p>
      <p v-if="error" class="error-text">{{ error }}</p>

      <!-- <p>{{resetSearchText ? 'true': 'false'}}</p> -->
      <!-- <pre>{{JSON.stringify(parentObjectTitle, null, 2)}}</pre> -->
    </div>
    <InputComponent
      v-if="searchInputText === 'Other' && !disabled"
      type="text"
      :modelValue="customInput"
      @update:modelValue="handlerCustomValue"
      :label="selectedOption && selectedOption.label"
      :name="selectedOption && selectedOption.title"
      :helper-text="selectedOption && selectedOption.helperText"
    />
  </div>
</template>

<script>
import CevronDown from './../icons/chevron/ChevronDown.vue'
import ChevronUp from './../icons/chevron/ChevronUp.vue'
import InputComponent from './InputComponent.vue'
// import { toRaw } from 'vue';

export default {
  props: {
    name: String,
    label: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: [String], default: 'Select ...' },
    floatingLabel: { type: Boolean, default: false },
    options: {
      type: Array,
      default: () => []
    },
    // selectedOption: [Number, String, Array],
    error: { type: [String, Boolean], required: false },
    helperText: { type: String, required: false },
    resetSearchText: { type: Boolean, default: false }, // flag to clear the display text
    planType: String
  },
  components: {
    CevronDown,
    ChevronUp,
    InputComponent
  },
  mounted () {
    this.searchInputText = this.getSearchInputText()
    this.customInput = this.options.find((opt) => opt.isSelected)?.value
    this.selectedOption = this.options.find((opt) => opt.isSelected)

    //    this.customInput = this.options.find((opt) => opt.isSelected)?.value
    document.addEventListener('click', this.onClickOutside)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.onClickOutside)
  },
  data () {
    return {
      isOpen: false,
      filterByText: false,
      searchInputText: '',
      customInput: '',
      selectedOption: {}
    }
  },
  computed: {
    isLableFloating () {
      return this.floatingLabel
    },
    filteredOptions () {
      if (this.filterByText) {
        return this.options.filter((opt) =>
          opt.title.toLowerCase().includes(this.searchInputText.toLowerCase())
        )
      }
      return this.options
    }
  },
  methods: {
    getSearchInputText () {
      if (this.resetSearchText) return ''

      if (this.planType) return this.name

      return this.options.find((opt) => opt.isSelected)?.title
    },
    handlerCustomValue (e) {
      this.customInput = e.target.value
      e.target.name = this.name
      // e.target.value = this.customInput
      // e.target.id = this.selectedOption.title
      this.$emit('update:customInputOption', e)
      this.isOpen = false
    },
    toggleDropDown (status) {
      this.isOpen = status
    },
    onChangeHandler (event) {
      const value = event.target.value
      this.searchInputText = value
      this.filterByText = !!value
      this.isOpen = true
      this.selectedOption = this.options.find((opt) => opt.isSelected)
    },
    selectHandler (e, option) {
      this.selectedOption = option
      this.searchInputText = option.title
      e.target.name = this.name
      e.target.type = 'dropdown'
      e.target.title = option.title
      e.target.value = option.value || option.title
      e.target.customInput = this.customInput
      this.$emit('update:selectedOption', e)
      this.isOpen = false
    },

    onClickOutside (event) {
      const myElementToCheckIfClicksAreInsideOf = document.querySelector(
        `#${this.name}`
      )
      if (
        (myElementToCheckIfClicksAreInsideOf &&
          myElementToCheckIfClicksAreInsideOf.contains(event.target)) ||
        event.target.id === 'Rectangle' ||
        event.target.id === this.name
      ) {
        // console.log('clicked inside')
      } else {
        // console.log('clicked outside')
        this.isOpen = false
      }
    }
  },
  watch: {
    options: {
      handler: function (newVal) {
        this.customInput = this.options.find((opt) => opt.isSelected)?.value
        this.selectedOption = this.options.find((opt) => opt.isSelected)
      },
      deep: true
    },
    planType: function () {
      this.searchInputText = this.name
    }
  }
}
</script>

<style>
</style>
