<template>
  <div :class="['input-field', type, error && 'error']">
    <label
      :class="['label', isLableFloating ? 'floating' : '']"
      v-if="!isCustomInput && type !== 'checkbox'"
      >{{ label }}</label
    >

    <div v-if="isTextarea">
      <textarea
        type="textarea"
        :name="name || label"
        :placeholder="placeholder"
        :value="disabled ? '' : modelValue"
        @input="onChangeHandler"
        :disabled="disabled"
        :id="id"
      />
    </div>

    <div v-else-if="isCustomInput" class="custom-input-in-radio">
      <input
        :type="type"
        :name="name || label"
        :placeholder="placeholder"
        :value="modelValue"
        :checked="modelValue"
        @input="onChangeHandler"
        :disabled="disabled"
        :id="id"
        class=""
      />
      <input
        type="text"
        name="custom-input"
        placeholder="Other"
        :value="customValue"
        @input=" onChangeHandler"
        :disabled="!modelValue"
        :id="id"
        class="custom-input-in-radio-input"
      />
    </div>

    <div v-else :class="['inner', type]">
<!-- {{modelValue ? 'true': 'false'}} -->
      <input
        :type="type"
        :name="name || label"
        :placeholder="placeholder"
        :value="modelValue"
        :checked="modelValue"
        @input="onChangeHandler"
        :disabled="disabled"
        :id="id"
      />
      <!-- <p>{{modelValue ? 'value': 'novalue'}}</p> -->
    </div>

        <label
      :class="['label', isLableFloating ? 'floating' : '']"
      v-if="!isCustomInput && type === 'checkbox'"
      >{{ label }}</label
    >

    <!-- @change="onChangeHandler" -->
    <p v-if="helperText && !error" class="helper-text">{{ helperText }}</p>
    <p v-if="error" class="error-text">{{ error }}</p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [String, Boolean, Number],
    customValue: [String],
    label: String,
    isTextarea: Boolean,
    floatingLabel: { type: Boolean, default: false },
    name: {
      type: String
    },
    id: {
      type: String
    },
    isCustomInput: Boolean,
    placeholder: String,
    type: { default: 'text', type: String },
    error: [String, Boolean],
    helperText: String,
    disabled: [Boolean, String]
  },
  data () {
    return {
      sample: ''
    }
  },
  onMounted () {
    console.log(this.$props)
  },
  computed: {
    isLableFloating () {
      return this.floatingLabel
    }
  },
  methods: {
    onChangeHandler (event) {
      this.$emit('update:modelValue', event)
    }
  }
}
</script>

<style>
</style>
